export interface FoCustomersV3Dto {
  '@odata.etag': string
  dataAreaId: string
  CustomerAccount: string
  AddressBrazilianCNPJOrCPF: string
  PartyType: string
  PrimaryContactFaxExtension: string
  IsFuelSurchargeApplied: string
  SalesTaxGroup: string
  AddressCountryRegionId: string
  ContactPersonId: string
  CustomerPaymentFineCode: string
  BirthCountyCode: string
  InvoiceAddress: string
  PackingMaterialFeeLicenseNumber: string
  TransactionPresenceType: string
  PrimaryContactTwitter: string
  PrimaryContactEmailIsIM: string
  InvoiceAddressCity: string
  IsFinalUser: string
  PrimaryContactEmail: string
  DeliveryAddressCounty: string
  HasSuframaDiscountPISandCOFINS: string
  InvoiceAddressStreet: string
  CURPNumber: string
  PrimaryContactLinkedInDescription: string
  DeliveryAddressCountryRegionId: string
  ItemCustomerGroupId: string
  PersonProfessionalTitle: string
  SalesSegmentId: string
  IsServiceDeliveryAddressBased: string
  PrimaryContactTelexDescription: string
  InvoiceAddressDistrictName: string
  InvoiceAddressTimeZone: null | string
  SalesAccountNumber: string
  PrimaryContactFacebookDescription: string
  PrimaryContactPhoneIsMobile: string
  PrimaryContactFaxRecordId: number
  TCSGroup: string
  DeliveryAddressLocationId: string
  InterCompanyAutoCreateOrders: string
  AddressZipCode: string
  LineOfBusinessId: string
  GiroTypeProjInvoice: string
  OrganizationPhoneticName: string
  CreditCardCVC: string
  DeliveryAddressCountryRegionISOCode: string
  DeliveryAddressValidFrom: string
  GiroTypeAccountStatement: string
  CalculateWithholdingTax: string
  ElectronicInvoiceEAN: string
  DeliveryFreightZone: string
  PrimaryContactFax: string
  TaxExemptNumber: string
  DeliveryAddressDescription: string
  DeliveryAddressLongitude: number
  BirthPlace: string
  AddressCity: string
  PersonInitials: string
  DefaultECommerceOperator: string
  CentralBankPurposeCode: string
  FullPrimaryAddress: string
  GiroTypeCollectionletter: string
  OrderEntryDeadline: string
  ForeignerId: string
  WarehouseIsASNGenerated: string
  AddressLongitude: number
  CustomerType: string
  PrimaryContactLinkedIn: string
  PrimaryContactURL: string
  IsFreightAccrued: string
  PartyState: string
  InvoiceAccount: string
  PartyNumber: string
  CentralBankPurposeNotes: string
  CollectionsContactPersonId: string
  AddressBuildingComplement: string
  PersonMaritalStatus: string
  AddressLocationId: string
  PaymentSchedule: string
  PrimaryContactTwitterPurpose: string
  GiroType: string
  InvoiceAddressDescription: string
  AddressDistrictName: string
  DeliveryTerms: string
  NumberSequenceGroup: string
  PersonChildrenNames: string
  PaymentMethod: string
  ForeignResident: string
  DeliveryMode: string
  PersonProfessionalSuffix: string
  OnHoldStatus: string
  AccountStatement: string
  WarehouseId: string
  DestinationCode: string
  IRS1099CIndicator: string
  EInvoiceRegister: string
  FrenchSiret: string
  PaymentDay: string
  BrazilianNIT: string
  FederalIndicator: string
  EmployeeResponsibleNumber: string
  PersonAnniversaryYear: number
  DefaultInventoryStatusId: string
  PrimaryContactEmailDescription: string
  AddressTimeZone: null | string
  PrimaryContactPhone: string
  PrimaryContactPhoneRecordId: number
  DeliveryAddressCity: string
  InvoiceAddressCounty: string
  PANReferenceNumber: string
  DeliveryAddressStreet: string
  SalesCurrencyCode: string
  Priority: string
  IsSalesTaxIncludedInPrices: string
  BrazilianCNPJOrCPF: string
  PaymentFactoringAccount: string
  PANNumber: string
  PrimaryContactTwitterDescription: string
  PersonFirstName: string
  BrazilianCCM: string
  ReceiptEmail: string
  TDSGroup: string
  MultiLineDiscountCode: string
  PersonAnniversaryDay: number
  DefaultDimensionDisplayValue: string
  SupplementaryItemGroupId: string
  IsWithholdingTaxCalculated: string
  PaymentSpecification: string
  AddressRecordId: number
  AddressBooks: string
  PrimaryContactEmailPurpose: string
  FulfillmentPolicyName: string
  SalesDistrict: string
  ConsolidationDay: number
  IsExpressBillOfLadingAccepted: string
  CreditCardAddressVerification: string
  InvoiceAddressBuildingComplement: string
  AddressState: string
  AllowOnAccount: string
  IsExcludedFromCollectionFeeCalculation: string
  CustomerPaymentFinancialInterestCode: string
  IsTransactionPostedAsShipment: string
  IsExternallyMaintained: string
  CreditLimitIsMandatory: string
  PaymentTermsBaseDays: number
  FiscalCode: string
  KnownAs: string
  PrimaryContactTelex: string
  PaymentBankAccount: string
  BrazilianIE: string
  PreferentialCustomer: string
  PrimaryContactEmailRecordId: number
  ReceiptOption: string
  AddressBrazilianIE: string
  PersonAnniversaryMonth: string
  PrimaryContactPhoneExtension: string
  InvoiceAddressStreetNumber: string
  PrimaryContactPhonePurpose: string
  ExportSale: string
  OrganizationNumber: string
  InvoicePostingType: string
  CreditCardAddressVerificationIsAuthorizationVoidedOnFailure: string
  InvoiceAddressState: string
  ReceiptCalendar: string
  DeliveryAddressState: string
  StateInscription: string
  PersonLastNamePrefix: string
  SalesReturnTaxGroup: string
  PrimaryContactURLDescription: string
  CustomerWithholdingContributionType: string
  IsElectronicInvoice: string
  FederalAgencyLocationCode: string
  GiroTypeFreeTextInvoice: string
  DeliveryAddressValidTo: string
  PersonPhoneticMiddleName: string
  OrganizationABCCode: string
  BrazilianCNAE: string
  WithholdingTaxGroupCode: string
  NAFCode: string
  AddressLatitude: number
  TotalDiscountCode: string
  AddressLocationRoles: string
  PaymentCashDiscount: string
  DeliveryAddressDistrictName: string
  CollectionLetterCode: string
  EInvoiceAttachment: string
  MerchantID: string
  CreditRating: string
  PrimaryContactFacebook: string
  DeliveryAddressBuildingComplement: string
  DeliveryAddressStreetNumber: string
  LineDiscountCode: string
  InvoiceAddressLongitude: number
  PartyCountry: string
  InvoiceAddressLatitude: number
  IsInSuframaRegion: string
  IsIncomingFiscalDocumentGenerated: string
  SalesMemo: string
  ReliefGroupId: string
  IsExcludedFromInterestChargeCalculation: string
  StatisticsGroupId: string
  SalesOrderPoolId: string
  OrganizationName: string
  IsOrderNumberReferenceUsed: string
  CustClassificationId: string
  AddressValidTo: string
  FederalComments: string
  PersonGender: string
  PaymentTerms: string
  PrimaryContactPhoneDescription: string
  AddressPostbox: string
  SalesSubsegmentId: string
  CustomerRebateGroupId: string
  AddressCounty: string
  PrimaryContactURLRecordId: number
  IsICMSContributor: string
  InvoiceAddressValidTo: string
  DeliveryAddressTimeZone: null | string
  InvoiceAddressValidFrom: string
  CreditLimit: number
  CreditCardAddressVerificationLevel: string
  WarehouseIsEntireShipmentFilled: string
  PackingDutyLicense: string
  NationalRegistryNumber: string
  SiteId: string
  IsAllowCreateIndirectOrderLines: string
  InvoiceAddressCountryRegionISOCode: string
  PrimaryContactURLPurpose: string
  ChargesGroupId: string
  PanStatus: string
  CommissionSalesGroupId: string
  NameAlias: string
  PrimaryContactFaxPurpose: string
  AddressStreetNumber: string
  PersonLastName: string
  PaymentIdType: string
  VendorAccount: string
  AddressCountryRegionISOCode: string
  ResidenceForeignCountryRegionId: string
  RFCNumber: string
  PersonHobbies: string
  ElectronicLocationId: string
  InvoiceAddressZipCode: string
  PrimaryContactLinkedInPurpose: string
  IsOneTimeCustomer: string
  ForeignCustomer: string
  CustomerTMAGroupId: string
  AddressValidFrom: string
  OrganizationNumberOfEmployees: number
  CompanyType: string
  CustomerGroupId: string
  GiroTypeInterestNote: string
  DeliveryAddressZipCode: string
  DeliveryReason: string
  TaxRegistrationId: string
  PaymentUseCashDiscount: string
  DiscountPriceGroupId: string
  AddressDescription: string
  SuframaNumber: string
  IdentificationNumber: string
  PersonPhoneticFirstName: string
  AuthorityOffice: string
  PrimaryContactTelexPurpose: string
  DeliveryAddressLatitude: number
  InvoiceAddressCountryRegionId: string
  PrimaryContactFacebookPurpose: string
  CompanyChain: string
  OverrideSalesTax: string
  BrazilianINSSCEI: string
  CommissionCustomerGroupId: string
  PrimaryContactFaxDescription: string
  NatureOfAssessee: string
  WriteoffReason: string
  PersonMiddleName: string
  InvoiceAddressLocationId: string
  LanguageId: string
  PersonPhoneticLastName: string
  AddressStreet: string
  IsPurchRequestUsed: string
  IsPublicSector_IT: string
  SATRegistrationName_MX: string
  TaxRegimeCode_MX: string
  CFDITemporaryExport_MX: string
  IsSimplifiedPrimaryAddress: string
  IsSimplifiedDeliveryAddress: string
  IsSimplifiedInvoiceAddress: string
  CredManEligibleCreditLimitCurrency: string
  CredManCustCreditMaxAlt: number
  CredManStatusReasonId: string
  CredManEligibleCreditMax: number
  CredManCustUnlimitedCredit: string
  CredManEligibleCreditLimitDate: string
  CredManWithAgency: string
  CredManCreditLimitDate: string
  CredManNextSchedReviewDate: string
  CredManLastReviewDate: string
  CredManCustomerSince: string
  CredManTitleHeld: string
  CredManExclude: string
  CredManBusinessStarted: string
  CredManNotes: string
  CredManCollectionGroupId: string
  CredManGroupId: string
  CredManAccountStatusId: string
  CredManCreditLimitExpiryDate: string
  GPLBankStatementAutobanking: string
  GPLRemainingItems: string
  GPLVatCheckMode: string
  GPLAutoChargesDateTypeOverride: string
  GPLAutoChargesDateType: string
  GPLEnableRemovalRemainingQty: string
  GPLPercentRemaining: number
  GPLNumberOfDaysMin: number
  GPLShowInternalMemo: string
  GPLExternalMemo: string
  GPLInternalMemo: string
  GPLKitDetailsOnSalesPackingSlip: string
  GPLKitDetailsOnSalesInvoice: string
  GPLRACustGroup: string
  GPLMarkupAutoDisableForcedRefresh: string
  GPLMarkupAutoDisableForcedRefreshOverride: string
  GPLReferencingControl: string
  GPLNodeId: number
  GPLMainAffacturor: string
  GPLTransportFreeAmount: number
  GPLELCCarrierName: string
  GPLTransportMarkupInvoiceMode: string
  GPLWHSLabelGroup: string
  SPLRecId: number
  SPLWithOrderForm: string
  SPLMandatoryExternalRef: string
  SPLMandatorySignature: string
  SPLBillingContactPersonRecId: number
  SPLWithEquipmentList: string
  SPLOtherBillingAddresss: string
  SPLBillingPlatformId: string
  SPLWithAdvanceInvoice: string
  SPLCreatedDateTime: string
  SPLBillingContactPersonId: string
  SPLHeadOfOpticalSector: string
  SPLRecVersion: number
  SPLModifiedDateTime: string
  InterCompanyDirectDelivery: string
  SPLWithDeliveryOrder: string
  SPLDetectionType: string
}
export enum FoCustomersV3DtoFields {
  ODataEtag = '@odata.etag',
  DataAreaId = 'dataAreaId',
  CustomerAccount = 'CustomerAccount',
  AddressBrazilianCNPJOrCPF = 'AddressBrazilianCNPJOrCPF',
  PartyType = 'PartyType',
  PrimaryContactFaxExtension = 'PrimaryContactFaxExtension',
  IsFuelSurchargeApplied = 'IsFuelSurchargeApplied',
  SalesTaxGroup = 'SalesTaxGroup',
  AddressCountryRegionId = 'AddressCountryRegionId',
  ContactPersonId = 'ContactPersonId',
  CustomerPaymentFineCode = 'CustomerPaymentFineCode',
  BirthCountyCode = 'BirthCountyCode',
  InvoiceAddress = 'InvoiceAddress',
  PackingMaterialFeeLicenseNumber = 'PackingMaterialFeeLicenseNumber',
  TransactionPresenceType = 'TransactionPresenceType',
  PrimaryContactTwitter = 'PrimaryContactTwitter',
  PrimaryContactEmailIsIM = 'PrimaryContactEmailIsIM',
  InvoiceAddressCity = 'InvoiceAddressCity',
  IsFinalUser = 'IsFinalUser',
  PrimaryContactEmail = 'PrimaryContactEmail',
  DeliveryAddressCounty = 'DeliveryAddressCounty',
  HasSuframaDiscountPISandCOFINS = 'HasSuframaDiscountPISandCOFINS',
  InvoiceAddressStreet = 'InvoiceAddressStreet',
  CURPNumber = 'CURPNumber',
  PrimaryContactLinkedInDescription = 'PrimaryContactLinkedInDescription',
  DeliveryAddressCountryRegionId = 'DeliveryAddressCountryRegionId',
  ItemCustomerGroupId = 'ItemCustomerGroupId',
  PersonProfessionalTitle = 'PersonProfessionalTitle',
  SalesSegmentId = 'SalesSegmentId',
  IsServiceDeliveryAddressBased = 'IsServiceDeliveryAddressBased',
  PrimaryContactTelexDescription = 'PrimaryContactTelexDescription',
  InvoiceAddressDistrictName = 'InvoiceAddressDistrictName',
  InvoiceAddressTimeZone = 'InvoiceAddressTimeZone',
  SalesAccountNumber = 'SalesAccountNumber',
  PrimaryContactFacebookDescription = 'PrimaryContactFacebookDescription',
  PrimaryContactPhoneIsMobile = 'PrimaryContactPhoneIsMobile',
  PrimaryContactFaxRecordId = 'PrimaryContactFaxRecordId',
  TCSGroup = 'TCSGroup',
  DeliveryAddressLocationId = 'DeliveryAddressLocationId',
  InterCompanyAutoCreateOrders = 'InterCompanyAutoCreateOrders',
  AddressZipCode = 'AddressZipCode',
  LineOfBusinessId = 'LineOfBusinessId',
  GiroTypeProjInvoice = 'GiroTypeProjInvoice',
  OrganizationPhoneticName = 'OrganizationPhoneticName',
  CreditCardCVC = 'CreditCardCVC',
  DeliveryAddressCountryRegionISOCode = 'DeliveryAddressCountryRegionISOCode',
  DeliveryAddressValidFrom = 'DeliveryAddressValidFrom',
  GiroTypeAccountStatement = 'GiroTypeAccountStatement',
  CalculateWithholdingTax = 'CalculateWithholdingTax',
  ElectronicInvoiceEAN = 'ElectronicInvoiceEAN',
  DeliveryFreightZone = 'DeliveryFreightZone',
  PrimaryContactFax = 'PrimaryContactFax',
  TaxExemptNumber = 'TaxExemptNumber',
  DeliveryAddressDescription = 'DeliveryAddressDescription',
  DeliveryAddressLongitude = 'DeliveryAddressLongitude',
  BirthPlace = 'BirthPlace',
  AddressCity = 'AddressCity',
  PersonInitials = 'PersonInitials',
  DefaultECommerceOperator = 'DefaultECommerceOperator',
  CentralBankPurposeCode = 'CentralBankPurposeCode',
  FullPrimaryAddress = 'FullPrimaryAddress',
  GiroTypeCollectionletter = 'GiroTypeCollectionletter',
  OrderEntryDeadline = 'OrderEntryDeadline',
  ForeignerId = 'ForeignerId',
  WarehouseIsASNGenerated = 'WarehouseIsASNGenerated',
  AddressLongitude = 'AddressLongitude',
  CustomerType = 'CustomerType',
  PrimaryContactLinkedIn = 'PrimaryContactLinkedIn',
  PrimaryContactURL = 'PrimaryContactURL',
  IsFreightAccrued = 'IsFreightAccrued',
  PartyState = 'PartyState',
  InvoiceAccount = 'InvoiceAccount',
  PartyNumber = 'PartyNumber',
  CentralBankPurposeNotes = 'CentralBankPurposeNotes',
  CollectionsContactPersonId = 'CollectionsContactPersonId',
  AddressBuildingComplement = 'AddressBuildingComplement',
  PersonMaritalStatus = 'PersonMaritalStatus',
  AddressLocationId = 'AddressLocationId',
  PaymentSchedule = 'PaymentSchedule',
  PrimaryContactTwitterPurpose = 'PrimaryContactTwitterPurpose',
  GiroType = 'GiroType',
  InvoiceAddressDescription = 'InvoiceAddressDescription',
  AddressDistrictName = 'AddressDistrictName',
  DeliveryTerms = 'DeliveryTerms',
  NumberSequenceGroup = 'NumberSequenceGroup',
  PersonChildrenNames = 'PersonChildrenNames',
  PaymentMethod = 'PaymentMethod',
  ForeignResident = 'ForeignResident',
  DeliveryMode = 'DeliveryMode',
  PersonProfessionalSuffix = 'PersonProfessionalSuffix',
  OnHoldStatus = 'OnHoldStatus',
  AccountStatement = 'AccountStatement',
  WarehouseId = 'WarehouseId',
  DestinationCode = 'DestinationCode',
  IRS1099CIndicator = 'IRS1099CIndicator',
  EInvoiceRegister = 'EInvoiceRegister',
  FrenchSiret = 'FrenchSiret',
  PaymentDay = 'PaymentDay',
  BrazilianNIT = 'BrazilianNIT',
  FederalIndicator = 'FederalIndicator',
  EmployeeResponsibleNumber = 'EmployeeResponsibleNumber',
  PersonAnniversaryYear = 'PersonAnniversaryYear',
  DefaultInventoryStatusId = 'DefaultInventoryStatusId',
  PrimaryContactEmailDescription = 'PrimaryContactEmailDescription',
  AddressTimeZone = 'AddressTimeZone',
  PrimaryContactPhone = 'PrimaryContactPhone',
  PrimaryContactPhoneRecordId = 'PrimaryContactPhoneRecordId',
  DeliveryAddressCity = 'DeliveryAddressCity',
  InvoiceAddressCounty = 'InvoiceAddressCounty',
  PANReferenceNumber = 'PANReferenceNumber',
  DeliveryAddressStreet = 'DeliveryAddressStreet',
  SalesCurrencyCode = 'SalesCurrencyCode',
  Priority = 'Priority',
  IsSalesTaxIncludedInPrices = 'IsSalesTaxIncludedInPrices',
  BrazilianCNPJOrCPF = 'BrazilianCNPJOrCPF',
  PaymentFactoringAccount = 'PaymentFactoringAccount',
  PANNumber = 'PANNumber',
  PrimaryContactTwitterDescription = 'PrimaryContactTwitterDescription',
  PersonFirstName = 'PersonFirstName',
  BrazilianCCM = 'BrazilianCCM',
  ReceiptEmail = 'ReceiptEmail',
  TDSGroup = 'TDSGroup',
  MultiLineDiscountCode = 'MultiLineDiscountCode',
  PersonAnniversaryDay = 'PersonAnniversaryDay',
  DefaultDimensionDisplayValue = 'DefaultDimensionDisplayValue',
  SupplementaryItemGroupId = 'SupplementaryItemGroupId',
  IsWithholdingTaxCalculated = 'IsWithholdingTaxCalculated',
  PaymentSpecification = 'PaymentSpecification',
  AddressRecordId = 'AddressRecordId',
  AddressBooks = 'AddressBooks',
  PrimaryContactEmailPurpose = 'PrimaryContactEmailPurpose',
  FulfillmentPolicyName = 'FulfillmentPolicyName',
  SalesDistrict = 'SalesDistrict',
  ConsolidationDay = 'ConsolidationDay',
  IsExpressBillOfLadingAccepted = 'IsExpressBillOfLadingAccepted',
  CreditCardAddressVerification = 'CreditCardAddressVerification',
  InvoiceAddressBuildingComplement = 'InvoiceAddressBuildingComplement',
  AddressState = 'AddressState',
  AllowOnAccount = 'AllowOnAccount',
  IsExcludedFromCollectionFeeCalculation = 'IsExcludedFromCollectionFeeCalculation',
  CustomerPaymentFinancialInterestCode = 'CustomerPaymentFinancialInterestCode',
  IsTransactionPostedAsShipment = 'IsTransactionPostedAsShipment',
  IsExternallyMaintained = 'IsExternallyMaintained',
  CreditLimitIsMandatory = 'CreditLimitIsMandatory',
  PaymentTermsBaseDays = 'PaymentTermsBaseDays',
  FiscalCode = 'FiscalCode',
  KnownAs = 'KnownAs',
  PrimaryContactTelex = 'PrimaryContactTelex',
  PaymentBankAccount = 'PaymentBankAccount',
  BrazilianIE = 'BrazilianIE',
  PreferentialCustomer = 'PreferentialCustomer',
  PrimaryContactEmailRecordId = 'PrimaryContactEmailRecordId',
  ReceiptOption = 'ReceiptOption',
  AddressBrazilianIE = 'AddressBrazilianIE',
  PersonAnniversaryMonth = 'PersonAnniversaryMonth',
  PrimaryContactPhoneExtension = 'PrimaryContactPhoneExtension',
  InvoiceAddressStreetNumber = 'InvoiceAddressStreetNumber',
  PrimaryContactPhonePurpose = 'PrimaryContactPhonePurpose',
  ExportSale = 'ExportSale',
  OrganizationNumber = 'OrganizationNumber',
  InvoicePostingType = 'InvoicePostingType',
  CreditCardAddressVerificationIsAuthorizationVoidedOnFailure = 'CreditCardAddressVerificationIsAuthorizationVoidedOnFailure',
  InvoiceAddressState = 'InvoiceAddressState',
  ReceiptCalendar = 'ReceiptCalendar',
  DeliveryAddressState = 'DeliveryAddressState',
  StateInscription = 'StateInscription',
  PersonLastNamePrefix = 'PersonLastNamePrefix',
  SalesReturnTaxGroup = 'SalesReturnTaxGroup',
  PrimaryContactURLDescription = 'PrimaryContactURLDescription',
  CustomerWithholdingContributionType = 'CustomerWithholdingContributionType',
  IsElectronicInvoice = 'IsElectronicInvoice',
  FederalAgencyLocationCode = 'FederalAgencyLocationCode',
  GiroTypeFreeTextInvoice = 'GiroTypeFreeTextInvoice',
  DeliveryAddressValidTo = 'DeliveryAddressValidTo',
  PersonPhoneticMiddleName = 'PersonPhoneticMiddleName',
  OrganizationABCCode = 'OrganizationABCCode',
  BrazilianCNAE = 'BrazilianCNAE',
  WithholdingTaxGroupCode = 'WithholdingTaxGroupCode',
  NAFCode = 'NAFCode',
  AddressLatitude = 'AddressLatitude',
  TotalDiscountCode = 'TotalDiscountCode',
  AddressLocationRoles = 'AddressLocationRoles',
  PaymentCashDiscount = 'PaymentCashDiscount',
  DeliveryAddressDistrictName = 'DeliveryAddressDistrictName',
  CollectionLetterCode = 'CollectionLetterCode',
  EInvoiceAttachment = 'EInvoiceAttachment',
  MerchantID = 'MerchantID',
  CreditRating = 'CreditRating',
  PrimaryContactFacebook = 'PrimaryContactFacebook',
  DeliveryAddressBuildingComplement = 'DeliveryAddressBuildingComplement',
  DeliveryAddressStreetNumber = 'DeliveryAddressStreetNumber',
  LineDiscountCode = 'LineDiscountCode',
  InvoiceAddressLongitude = 'InvoiceAddressLongitude',
  PartyCountry = 'PartyCountry',
  InvoiceAddressLatitude = 'InvoiceAddressLatitude',
  IsInSuframaRegion = 'IsInSuframaRegion',
  IsIncomingFiscalDocumentGenerated = 'IsIncomingFiscalDocumentGenerated',
  SalesMemo = 'SalesMemo',
  ReliefGroupId = 'ReliefGroupId',
  IsExcludedFromInterestChargeCalculation = 'IsExcludedFromInterestChargeCalculation',
  StatisticsGroupId = 'StatisticsGroupId',
  SalesOrderPoolId = 'SalesOrderPoolId',
  OrganizationName = 'OrganizationName',
  IsOrderNumberReferenceUsed = 'IsOrderNumberReferenceUsed',
  CustClassificationId = 'CustClassificationId',
  AddressValidTo = 'AddressValidTo',
  FederalComments = 'FederalComments',
  PersonGender = 'PersonGender',
  PaymentTerms = 'PaymentTerms',
  PrimaryContactPhoneDescription = 'PrimaryContactPhoneDescription',
  AddressPostbox = 'AddressPostbox',
  SalesSubsegmentId = 'SalesSubsegmentId',
  CustomerRebateGroupId = 'CustomerRebateGroupId',
  AddressCounty = 'AddressCounty',
  PrimaryContactURLRecordId = 'PrimaryContactURLRecordId',
  IsICMSContributor = 'IsICMSContributor',
  InvoiceAddressValidTo = 'InvoiceAddressValidTo',
  DeliveryAddressTimeZone = 'DeliveryAddressTimeZone',
  InvoiceAddressValidFrom = 'InvoiceAddressValidFrom',
  CreditLimit = 'CreditLimit',
  CreditCardAddressVerificationLevel = 'CreditCardAddressVerificationLevel',
  WarehouseIsEntireShipmentFilled = 'WarehouseIsEntireShipmentFilled',
  PackingDutyLicense = 'PackingDutyLicense',
  NationalRegistryNumber = 'NationalRegistryNumber',
  SiteId = 'SiteId',
  IsAllowCreateIndirectOrderLines = 'IsAllowCreateIndirectOrderLines',
  InvoiceAddressCountryRegionISOCode = 'InvoiceAddressCountryRegionISOCode',
  PrimaryContactURLPurpose = 'PrimaryContactURLPurpose',
  ChargesGroupId = 'ChargesGroupId',
  PanStatus = 'PanStatus',
  CommissionSalesGroupId = 'CommissionSalesGroupId',
  NameAlias = 'NameAlias',
  PrimaryContactFaxPurpose = 'PrimaryContactFaxPurpose',
  AddressStreetNumber = 'AddressStreetNumber',
  PersonLastName = 'PersonLastName',
  PaymentIdType = 'PaymentIdType',
  VendorAccount = 'VendorAccount',
  AddressCountryRegionISOCode = 'AddressCountryRegionISOCode',
  ResidenceForeignCountryRegionId = 'ResidenceForeignCountryRegionId',
  RFCNumber = 'RFCNumber',
  PersonHobbies = 'PersonHobbies',
  ElectronicLocationId = 'ElectronicLocationId',
  InvoiceAddressZipCode = 'InvoiceAddressZipCode',
  PrimaryContactLinkedInPurpose = 'PrimaryContactLinkedInPurpose',
  IsOneTimeCustomer = 'IsOneTimeCustomer',
  ForeignCustomer = 'ForeignCustomer',
  CustomerTMAGroupId = 'CustomerTMAGroupId',
  AddressValidFrom = 'AddressValidFrom',
  OrganizationNumberOfEmployees = 'OrganizationNumberOfEmployees',
  CompanyType = 'CompanyType',
  CustomerGroupId = 'CustomerGroupId',
  GiroTypeInterestNote = 'GiroTypeInterestNote',
  DeliveryAddressZipCode = 'DeliveryAddressZipCode',
  DeliveryReason = 'DeliveryReason',
  TaxRegistrationId = 'TaxRegistrationId',
  PaymentUseCashDiscount = 'PaymentUseCashDiscount',
  DiscountPriceGroupId = 'DiscountPriceGroupId',
  AddressDescription = 'AddressDescription',
  SuframaNumber = 'SuframaNumber',
  IdentificationNumber = 'IdentificationNumber',
  PersonPhoneticFirstName = 'PersonPhoneticFirstName',
  AuthorityOffice = 'AuthorityOffice',
  PrimaryContactTelexPurpose = 'PrimaryContactTelexPurpose',
  DeliveryAddressLatitude = 'DeliveryAddressLatitude',
  InvoiceAddressCountryRegionId = 'InvoiceAddressCountryRegionId',
  PrimaryContactFacebookPurpose = 'PrimaryContactFacebookPurpose',
  CompanyChain = 'CompanyChain',
  OverrideSalesTax = 'OverrideSalesTax',
  BrazilianINSSCEI = 'BrazilianINSSCEI',
  CommissionCustomerGroupId = 'CommissionCustomerGroupId',
  PrimaryContactFaxDescription = 'PrimaryContactFaxDescription',
  NatureOfAssessee = 'NatureOfAssessee',
  WriteoffReason = 'WriteoffReason',
  PersonMiddleName = 'PersonMiddleName',
  InvoiceAddressLocationId = 'InvoiceAddressLocationId',
  LanguageId = 'LanguageId',
  PersonPhoneticLastName = 'PersonPhoneticLastName',
  AddressStreet = 'AddressStreet',
  IsPurchRequestUsed = 'IsPurchRequestUsed',
  IsPublicSector_IT = 'IsPublicSector_IT',
  SATRegistrationName_MX = 'SATRegistrationName_MX',
  TaxRegimeCode_MX = 'TaxRegimeCode_MX',
  CFDITemporaryExport_MX = 'CFDITemporaryExport_MX',
  IsSimplifiedPrimaryAddress = 'IsSimplifiedPrimaryAddress',
  IsSimplifiedDeliveryAddress = 'IsSimplifiedDeliveryAddress',
  IsSimplifiedInvoiceAddress = 'IsSimplifiedInvoiceAddress',
  CredManEligibleCreditLimitCurrency = 'CredManEligibleCreditLimitCurrency',
  CredManCustCreditMaxAlt = 'CredManCustCreditMaxAlt',
  CredManStatusReasonId = 'CredManStatusReasonId',
  CredManEligibleCreditMax = 'CredManEligibleCreditMax',
  CredManCustUnlimitedCredit = 'CredManCustUnlimitedCredit',
  CredManEligibleCreditLimitDate = 'CredManEligibleCreditLimitDate',
  CredManWithAgency = 'CredManWithAgency',
  CredManCreditLimitDate = 'CredManCreditLimitDate',
  CredManNextSchedReviewDate = 'CredManNextSchedReviewDate',
  CredManLastReviewDate = 'CredManLastReviewDate',
  CredManCustomerSince = 'CredManCustomerSince',
  CredManTitleHeld = 'CredManTitleHeld',
  CredManExclude = 'CredManExclude',
  CredManBusinessStarted = 'CredManBusinessStarted',
  CredManNotes = 'CredManNotes',
  CredManCollectionGroupId = 'CredManCollectionGroupId',
  CredManGroupId = 'CredManGroupId',
  CredManAccountStatusId = 'CredManAccountStatusId',
  CredManCreditLimitExpiryDate = 'CredManCreditLimitExpiryDate',
  GPLBankStatementAutobanking = 'GPLBankStatementAutobanking',
  GPLRemainingItems = 'GPLRemainingItems',
  GPLVatCheckMode = 'GPLVatCheckMode',
  GPLAutoChargesDateTypeOverride = 'GPLAutoChargesDateTypeOverride',
  GPLAutoChargesDateType = 'GPLAutoChargesDateType',
  GPLEnableRemovalRemainingQty = 'GPLEnableRemovalRemainingQty',
  GPLPercentRemaining = 'GPLPercentRemaining',
  GPLNumberOfDaysMin = 'GPLNumberOfDaysMin',
  GPLShowInternalMemo = 'GPLShowInternalMemo',
  GPLExternalMemo = 'GPLExternalMemo',
  GPLInternalMemo = 'GPLInternalMemo',
  GPLKitDetailsOnSalesPackingSlip = 'GPLKitDetailsOnSalesPackingSlip',
  GPLKitDetailsOnSalesInvoice = 'GPLKitDetailsOnSalesInvoice',
  GPLRACustGroup = 'GPLRACustGroup',
  GPLMarkupAutoDisableForcedRefresh = 'GPLMarkupAutoDisableForcedRefresh',
  GPLMarkupAutoDisableForcedRefreshOverride = 'GPLMarkupAutoDisableForcedRefreshOverride',
  GPLReferencingControl = 'GPLReferencingControl',
  GPLNodeId = 'GPLNodeId',
  GPLMainAffacturor = 'GPLMainAffacturor',
  GPLTransportFreeAmount = 'GPLTransportFreeAmount',
  GPLELCCarrierName = 'GPLELCCarrierName',
  GPLTransportMarkupInvoiceMode = 'GPLTransportMarkupInvoiceMode',
  GPLWHSLabelGroup = 'GPLWHSLabelGroup',
  SPLRecId = 'SPLRecId',
  SPLWithOrderForm = 'SPLWithOrderForm',
  SPLMandatoryExternalRef = 'SPLMandatoryExternalRef',
  SPLMandatorySignature = 'SPLMandatorySignature',
  SPLBillingContactPersonRecId = 'SPLBillingContactPersonRecId',
  SPLWithEquipmentList = 'SPLWithEquipmentList',
  SPLOtherBillingAddresss = 'SPLOtherBillingAddresss',
  SPLBillingPlatformId = 'SPLBillingPlatformId',
  SPLWithAdvanceInvoice = 'SPLWithAdvanceInvoice',
  SPLCreatedDateTime = 'SPLCreatedDateTime',
  SPLBillingContactPersonId = 'SPLBillingContactPersonId',
  SPLHeadOfOpticalSector = 'SPLHeadOfOpticalSector',
  SPLRecVersion = 'SPLRecVersion',
  SPLModifiedDateTime = 'SPLModifiedDateTime',
  InterCompanyDirectDelivery = 'InterCompanyDirectDelivery',
  SPLWithDeliveryOrder = 'SPLWithDeliveryOrder',
  SPLDetectionType = 'SPLDetectionType',
}
export const RequestableFoCustomersV3DtoFields = [
  FoCustomersV3DtoFields.CustomerAccount,
  FoCustomersV3DtoFields.CustomerGroupId,
  FoCustomersV3DtoFields.ContactPersonId,
  FoCustomersV3DtoFields.PartyNumber,
  FoCustomersV3DtoFields.GPLExternalMemo,
  FoCustomersV3DtoFields.GPLNodeId,
  FoCustomersV3DtoFields.GPLMainAffacturor,
  FoCustomersV3DtoFields.DeliveryMode,
  FoCustomersV3DtoFields.CustomerType,
  FoCustomersV3DtoFields.DataAreaId,
  FoCustomersV3DtoFields.PersonFirstName,
  FoCustomersV3DtoFields.PersonLastName,
  FoCustomersV3DtoFields.PrimaryContactEmail,
  FoCustomersV3DtoFields.PrimaryContactPhone,
  FoCustomersV3DtoFields.PersonGender
] as string[];
export interface RequestableFoCustomersV3Dto {
  CustomerAccount: string
  CustomerGroupId: string
  ContactPersonId: string
  PartyNumber: string
  GPLExternalMemo: string
  GPLNodeId: number
  GPLMainAffacturor: string
  DeliveryMode : string
  CustomerType: string
  dataAreaId: string
  PersonFirstName: string
  PersonLastName: string
  PrimaryContactEmail: string
  PrimaryContactPhone: string
  PersonGender: string
}
